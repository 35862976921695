import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH, EVENT_MOVE_TO_QUEUE_LIMIT_MS } from 'common/config';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const InactiveModalComponent: React.FC<AllType> = (props) => {
  const { inactiveModalParams } = props;
  const { open, title, description, onResumeClick, onPauseClick, onMoveClick } = inactiveModalParams;
  const [time, setTime] = useState<number>(EVENT_MOVE_TO_QUEUE_LIMIT_MS);

  useEffect(() => {
    let myInterval: NodeJS.Timer;

    if (open) {
      myInterval = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);
    }

    return () => {
      clearInterval(myInterval);
      setTime(EVENT_MOVE_TO_QUEUE_LIMIT_MS);
    };
  }, [open]);

  useEffect(() => {
    if (time === 0) {
      onMoveClick?.();
    }
  }, [time]);

  return (
    <Modal title={title} open={open} footer={false} width={DEFAULT_MODAL_WIDTH} closable={false}>
      <div className="modal__body">{description}</div>

      <div className="timer__footer">
        <Button className="btn-primary" icon={<PlayCircleOutlined />} onClick={onResumeClick}>
          Resume
        </Button>
        {onPauseClick && (
          <Button className="btn-orange" icon={<PauseCircleOutlined />} onClick={onPauseClick}>
            Pause
          </Button>
        )}
        <Button onClick={onMoveClick}>{`Move event to Queue ${time / 1000}`}</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  inactiveModalParams: state.modals.inactiveModalParams,
});

export const InactiveModal = connect(mapState)(InactiveModalComponent);
