import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import {
  EDateFormat,
  EEventStatuses,
  EOrderDirections,
  EEventOrderFields,
  ESearchParams,
  EEventTypesShort,
} from 'common/const/enums.const';
import { getTimeFromZone } from 'common/helpers/timezone.helper';
import { getBestTimeToCall } from 'common/helpers/time.helper';
import { IEventsItemDto } from 'entities/Dashboard/Dashboard.models';

export const getEventsSearchParams = (searchParams: URLSearchParams) => {
  const status = searchParams.get(ESearchParams.Status);
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);
  const assigneeId = searchParams.get(ESearchParams.AssigneeId);
  const eventType = searchParams.get(ESearchParams.EventType);

  return {
    status: (status ? status : EEventStatuses.Inbox) as EEventStatuses,
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    orderField: (orderField ? orderField : EEventOrderFields.Date) as EEventOrderFields,
    orderDirection: (orderDirection ? orderDirection : EOrderDirections.DESC) as EOrderDirections,
    assigneeId: assigneeId ? Number(assigneeId) : undefined,
    type: (eventType || undefined) as Exclude<EEventTypesShort, EEventTypesShort.All>,
  };
};

export const getMomentDifference = (firstDate: string, secondDate: string) => {
  return moment(firstDate).diff(moment(secondDate));
};

export const getFormattedMomentDifference = (milliseconds: number) => {
  const days = moment.duration(milliseconds).days();
  const hours = moment.duration(milliseconds).hours();
  const minutes = moment.duration(milliseconds).minutes();
  return days > 0 && hours > 0
    ? `${days}d ${hours}h`
    : days > 0 && hours <= 0
    ? `${days}d`
    : days <= 0 && hours > 0
    ? `${hours}h`
    : `${minutes}m`;
};

export const renderEventsRecords = (status: EEventStatuses) => {
  const columns: ColumnsType<IEventsItemDto> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (text) => moment(text).utc().format(EDateFormat.FullYear),
    },
    {
      title: 'Assigned to',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
    },
    {
      title: 'Regenesis Account Number',
      dataIndex: 'patientMrn',
      key: 'patientMrn',
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
      sorter: true,
    },
    {
      title: 'Patient Local Time',
      dataIndex: 'patientLocalTime',
      key: 'patientLocalTime',
      render: (_, record) => getTimeFromZone(record.patientLocalTime),
    },
    {
      title: 'Best Time To Call',
      dataIndex: 'bestTimeToCall',
      key: 'bestTimeToCall',
      render: (_, record) => getBestTimeToCall(record.bestTimeToCall),
    },
    {
      title: 'Sales Manager',
      dataIndex: 'salesManager',
      key: 'salesManager',
    },
    {
      title: 'Event Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },
    {
      title: 'Age',
      dataIndex: 'date',
      key: 'age',
      sorter: true,
      render: (_, record) => {
        const milliseconds = getMomentDifference(moment().toISOString(), record.date);
        return getFormattedMomentDifference(milliseconds);
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
  ];

  if (status === EEventStatuses.OnHold) {
    columns.splice(1, 0, {
      title: 'Follow Up Date',
      key: 'holdUntil',
      dataIndex: 'holdUntil',
    });
  }

  return columns;
};
