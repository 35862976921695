import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

interface IComponentProps {
  role?: string;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const DashboardSubheaderComponent: React.FC<AllType> = ({ statisticsModel }) => {
  const { dashboardStatisticsData, loading } = statisticsModel;

  return (
    <Spin spinning={loading}>
      <div className="statistics__container">
        <StatisticsItem title="In Queue" count={dashboardStatisticsData?.pendingCount} />
        <StatisticsItem title="Completed" subtitle="for today" count={dashboardStatisticsData?.completedTodayCount} />
        <StatisticsItem
          title="Total review time for"
          subtitle="today"
          count={convertToTimerFormat(dashboardStatisticsData?.totalTimeToday)}
          isPrimary
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statisticsModel: state.statisticsModel,
});

export const DashboardSubheader = connect(mapState)(DashboardSubheaderComponent);
