import axios from 'axios';
import { IAuthDto, IAuthModelPayload, IAuthModelUrl } from 'entities/Auth/Auth.models';

const basePath = 'auth';

export const authTransport = {
  addAuthModel: (params: IAuthModelPayload): Promise<IAuthDto> => axios.get(`${basePath}/callback`, { params }),
  refreshToken: (refreshToken: string): Promise<IAuthDto> => axios.put(`${basePath}`, { refreshToken }),
  deleteAuthModel: (): Promise<void> => axios.delete(`${basePath}`),
  getAuthModelCode: (): Promise<IAuthModelUrl> => axios.get(`${basePath}/code`),
};
