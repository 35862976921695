import axios from 'axios';
import { IEventChangeUnableToContactPayload, IEventModelChangeStatusPayload, IEventModelDto } from 'entities/Event/Event.models';

const basePath = 'events';

export const eventTransport = {
  getEventById: (eventId: number): Promise<IEventModelDto> => axios.get(`${basePath}/review/${eventId}`),
  changeEventStatus: (payload: IEventModelChangeStatusPayload): Promise<IEventModelDto> =>
    axios.patch(`${basePath}/status`, payload),
  changeEventUnableToContact: (payload: IEventChangeUnableToContactPayload): Promise<IEventModelDto> =>
    axios.patch(`${basePath}/unable-to-contact`, payload),
};
