import React from 'react';
import { Button, Checkbox } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { IdleTimer } from 'common/components/IdleTimer';
import { EVENT_IDLE_LIMIT_MS } from 'common/config';
import {
  EActionTypes,
  EDateFormat,
  EEventStatuses,
  EEventTaskStatuses,
  EHoldReason,
  EHoldReasonLabel,
} from 'common/const/enums.const';
import { RootDispatch, RootState, history } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/edit-icon.svg';
import { ReactComponent as SnoozeIcon } from 'app/assets/images/svg/snooze-icon.svg';
import { EventPhone } from 'entities/Phone/components/EventPhone';
import { getEventAvailableOperations } from 'entities/Event/Event.helper';
import { EventTimer } from 'entities/Timer/components/EventTimer';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventActionsCardComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    autoStartTimer,
    authModel,
    eventModel,
    tasksCollection,
    eventTimerModel,
    phoneModel,
    setCommentModalParams,
    setHoldModalParams,
    setOutdatedModalParams,
    addEventReviewCommentHistory,
    setActionParams,
    showEventInactiveModal,
    startEventTimer,
    stopEventTimer,
    changeEventStatus,
    changeEventUnableToContact,
  } = props;
  const { data: authModelData } = authModel;
  const { data: eventModelData } = eventModel;
  const { isRunning } = eventTimerModel;
  const { blockIdle } = phoneModel;
  const currentUserId = authModelData?.access?.userId;
  const assigneeId = eventModelData?.assigneeId;
  const eventStatus = eventModelData?.status;
  const phone = eventModelData?.phone;
  const primaryPhone = eventModelData?.primaryPhone;
  const holdUntil = eventModelData?.holdUntil;
  const holdReason = eventModelData?.holdReason;
  const unableToContact = eventModelData?.unableToContact;
  const { canBeMovedToInbox, canBeCompleted, canBeHeld, canStart, canBeOutdated } = getEventAvailableOperations(
    eventStatus,
    currentUserId,
    assigneeId
  );

  const handleCommentModalSend = (eventId: number, text: string) => {
    addEventReviewCommentHistory({ eventId, comment: text }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const handleCommentClick = () => {
    setCommentModalParams({
      open: true,
      externalId: eventId,
      onSendClick: handleCommentModalSend,
      onCancelClick: handleCommentModalCancel,
    });
  };

  const onMoveClick = () => {
    setActionParams({ type: EActionTypes.Move });
    history.back();
  };

  const onCompleteClick = () => {
    setActionParams({ type: EActionTypes.Complete });

    if (isRunning) {
      stopEventTimer({ eventId }).then(() => {
        history.back();
      });
    } else {
      history.back();
    }
  };

  const onPauseClick = () => {
    showEventInactiveModal({ open: false, title: null, description: null });
  };

  const onResumeClick = () => {
    startEventTimer(eventId).then(() => {
      onPauseClick();
    });
  };

  const disableComplete = () => {
    return tasksCollection.data?.some((task) => task.status !== EEventTaskStatuses.Completed);
  };

  const onHoldModalCancelClick = () => {
    setHoldModalParams({ open: false, defaultHoldUntil: null, defaultHoldReason: null });
  };

  const onHoldModalSaveClick = (holdUntil: string, holdReason: EHoldReason, comment?: string) => {
    setActionParams({ type: EActionTypes.Hold, holdUntil, holdReason, comment });

    if (eventStatus === EEventStatuses.OnHold) {
      setHoldModalParams({ open: false, defaultHoldUntil: null, defaultHoldReason: null });
      changeEventStatus({ eventId, status: EEventStatuses.OnHold, holdUntil, holdReason, comment }).then(() => {
        history.back();
      });
    } else {
      if (isRunning) {
        stopEventTimer({ eventId }).then(() => {
          history.back();
        });
      } else {
        history.back();
      }
    }
  };

  const onHoldModalReleaseClick = () => {
    setHoldModalParams({ open: false, defaultHoldUntil: null, defaultHoldReason: null });
    changeEventStatus({ eventId, status: EEventStatuses.Inbox }).then(() => {
      history.back();
    });
  };

  const onHoldClick = () => {
    setHoldModalParams({
      open: true,
      defaultHoldUntil: holdUntil,
      defaultHoldReason: holdReason,
      onSaveClick: onHoldModalSaveClick,
      onCancelClick: onHoldModalCancelClick,
      onReleaseClick: onHoldModalReleaseClick,
    });
  };

  const onOutdatedCancelClick = () => {
    setOutdatedModalParams({ open: false });
  };

  const onOutdatedClick = (outdatedReason: string) => {
    setOutdatedModalParams({ open: false });
    setActionParams({ type: EActionTypes.Outdated });
    changeEventStatus({ eventId, status: EEventStatuses.Outdated, outdatedReason }).then(() => {
      if (isRunning) {
        stopEventTimer({ eventId }).then(() => {
          history.back();
        });
      } else {
        history.back();
      }
    });
  };

  const onOutdated = () => {
    setOutdatedModalParams({
      open: true,
      onCancelClick: onOutdatedCancelClick,
      onOutdatedClick,
    });
  };

  const onUnableToContactChange = (value: boolean) => {
    changeEventUnableToContact({ eventId, unableToContact: value });
  };

  return (
    <div className="actions-card">
      <div className="actions-card__item">
        <EventTimer eventId={eventId} autoStartTimer={autoStartTimer} canStart={canStart} />
      </div>

      {!primaryPhone && !phone ? null : (
        <div className="actions-card__item">
          {primaryPhone && (
            <EventPhone title="Primary Phone" phone={primaryPhone} eventId={eventId} canStart={canStart} isRunning={isRunning} />
          )}
          {phone && <EventPhone title="Mobile Phone" phone={phone} eventId={eventId} canStart={canStart} isRunning={isRunning} />}
        </div>
      )}

      <div className="actions-card__item" style={{ paddingTop: 16 }}>
        <Checkbox checked={unableToContact} onChange={(e) => onUnableToContactChange(e.target.checked)}>
          Unable to contact
        </Checkbox>
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          <Button icon={<div className="icon actions-card__comment-icon" />} onClick={handleCommentClick}>
            Leave a comment
          </Button>

          <Button onClick={onMoveClick} disabled={!canBeMovedToInbox}>
            Move to inbox
          </Button>
        </div>
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          <Button
            className="btn-green"
            icon={<CheckCircleOutlined />}
            onClick={onCompleteClick}
            disabled={disableComplete() || !canBeCompleted}
          >
            Complete
          </Button>

          <Button onClick={onOutdated} disabled={!canBeOutdated}>
            Mark Outdated
          </Button>
        </div>
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          {canBeHeld && (
            <Button className="btn-orange" icon={<div className="icon actions-card__hold-icon" />} onClick={onHoldClick}>
              Hold
            </Button>
          )}
        </div>
      </div>

      {holdUntil && holdReason && (
        <div className="actions-card__item">
          <div className="actions-card__hold-info">
            <SnoozeIcon className="actions-card__hold-info-icon" />

            <div>
              <div>
                <span>The event is on hold until </span>
                <span className="bold">{`${moment(holdUntil).format(EDateFormat.FullMonthDay)}.`}</span>
              </div>

              <div>
                <span>Reason: </span>
                <span className="bold">{`${EHoldReasonLabel[holdReason]}.`}</span>
              </div>

              <Button className="btn-link btn-edit" icon={<EditIcon />} onClick={onHoldClick}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}

      {isRunning && !blockIdle && (
        <IdleTimer
          timeout={EVENT_IDLE_LIMIT_MS}
          onIdle={() => {
            stopEventTimer({ eventId }).then(() => {
              showEventInactiveModal({
                open: true,
                title: 'The timer is stopped',
                description: (
                  <>
                    <span>We stopped the timer due to non interaction.</span>
                    <span>Are you still reviewing?</span>
                  </>
                ),
                onResumeClick,
                onPauseClick,
                onMoveClick,
              });
            });
          }}
        />
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventModel: state.eventModel,
  tasksCollection: state.tasksCollection,
  eventTimerModel: state.eventTimerModel,
  phoneModel: state.phoneModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCommentModalParams: dispatch.modals.setCommentModalParams,
  setHoldModalParams: dispatch.modals.setHoldModalParams,
  setOutdatedModalParams: dispatch.modals.setOutdatedModalParams,
  addEventReviewCommentHistory: dispatch.eventReviewHistoryCollection.addEventReviewCommentHistory,
  setActionParams: dispatch.actionModel.setActionParams,
  showEventInactiveModal: dispatch.modals.showEventInactiveModal,
  startEventTimer: dispatch.eventTimerModel.startEventTimer,
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  changeEventStatus: dispatch.eventModel.changeEventStatus,
  changeEventUnableToContact: dispatch.eventModel.changeEventUnableToContact,
});

export const EventActionsCard = connect(mapState, mapDispatch)(EventActionsCardComponent);
