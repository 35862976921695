import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootState } from 'app/store';
import { TimeEditorItem } from 'entities/Timer/components/TimeEditorItem';

interface IComponentProps {
  withComment?: boolean;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const TimeEditorModal: React.FC<AllType> = (props) => {
  const { withComment, timeEditorModalParams, eventTimerLoading } = props;
  const { open, hours, minutes, seconds, reset, onSaveEditClick, onCancelEditClick } = timeEditorModalParams;
  const [newHoursValue, setNewHoursValue] = useState<number>(0);
  const [newMinutesValue, setNewMinutesValue] = useState<number>(0);
  const [newSecondsValue, setNewSecondsValue] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  const handleCancelClick = () => {
    onCancelEditClick?.();
    setComment('');
  };

  const handleSaveClick = () => {
    const milliseconds = (newHoursValue * 3600 + newMinutesValue * 60 + newSecondsValue) * 1000;

    onSaveEditClick?.(milliseconds, reset, comment);
    setComment('');
  };

  useEffect(() => {
    if (hours !== null && minutes !== null && seconds !== null) {
      setNewHoursValue(hours);
      setNewMinutesValue(minutes);
      setNewSecondsValue(seconds);
    }
  }, [hours, minutes, seconds]);

  return (
    <Modal title="Edit spent time" open={open} footer={false} onCancel={handleCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <Spin spinning={eventTimerLoading}>
        <div className="modal__body">
          <div className="timer__editor">
            <TimeEditorItem value={newHoursValue} setValue={setNewHoursValue} hours />

            <span>:</span>

            <TimeEditorItem value={newMinutesValue} setValue={setNewMinutesValue} />

            <span>:</span>

            <TimeEditorItem value={newSecondsValue} setValue={setNewSecondsValue} />
          </div>

          {withComment && (
            <>
              <p>Leave a comment</p>

              <Input.TextArea placeholder="Message" rows={6} value={comment} onChange={(e) => setComment(e.target.value)} />
            </>
          )}
        </div>

        <div className="timer__footer">
          <Button className="btn-primary" onClick={handleSaveClick} disabled={withComment ? !comment.length : false}>
            Save
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  timeEditorModalParams: state.modals.timeEditorModalParams,
  eventTimerLoading: state.eventTimerModel.loading,
});

export default connect(mapState)(TimeEditorModal);
