import { ColumnsType } from 'antd/lib/table';
import { ESearchParams, EOperationsOrderFields } from 'common/const/enums.const';
import { convertToMinutes } from 'common/helpers/time.helper';
import { IOperationDto } from 'entities/Operations/Operations.models';

export const getOperationsSearchParams = (searchParams: URLSearchParams) => {
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);

  return {
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    orderField: orderField ? orderField : EOperationsOrderFields.StaffName,
    orderDirection: orderDirection ? orderDirection : 'DESC',
  };
};

export const renderOperationsRecords = () => {
  return [
    {
      title: 'Staff member',
      dataIndex: 'staffName',
      key: 'staffName',
      sorter: true,
    },
    {
      title: 'Number of events',
      dataIndex: 'eventCount',
      key: 'eventCount',
      sorter: true,
    },
    {
      title: 'Total minutes spent',
      dataIndex: 'timeSpent',
      key: 'timeSpent',
      sorter: true,
      render: (text) => convertToMinutes(text),
    },
  ] as ColumnsType<IOperationDto>;
};
