import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IPatientModel, IPatientDto } from 'entities/Patient/Patient.models';
import { patientTransport } from 'entities/Patient/Patient.transport';

export const patientModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IPatientModel,
  reducers: {
    setPatientModel: (state, payload: IPatientDto) => ({ ...state, data: payload }),
    setPatientModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPatientModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPatientModel(eventId: number) {
      dispatch.patientModel.setPatientModelLoading(true);
      return patientTransport
        .getPatientModel(eventId)
        .then((response) => {
          dispatch.patientModel.setPatientModel(response);
        })
        .finally(() => {
          dispatch.patientModel.setPatientModelLoading(false);
        });
    },
  }),
});
