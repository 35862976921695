import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';

export const phoneModel = createModel<IRootModel>()({
  state: {
    blockRouter: true,
    blockIdle: false,
  },
  reducers: {
    setPhoneModelBlockRouter: (state, payload: boolean) => ({ ...state, blockRouter: payload }),
    setPhoneModelBlockIdle: (state, payload: boolean) => ({ ...state, blockIdle: payload }),
  },
});
