import axios from 'axios';
import { ITimeModelDto, IEventTimeModelPayload, ITimeDto, ITimeStopParams, ITimeCancelParams } from 'entities/Timer/Timer.models';

const basePath = 'times';

export const timerTransport = {
  getEventTimerModel: (eventId: number): Promise<ITimeModelDto> => axios.get(`${basePath}/info/${eventId}`),
  startEventTimer: (eventId: number): Promise<ITimeDto> => axios.post(`${basePath}/start`, { eventId }),
  stopEventTimer: (payload: ITimeStopParams): Promise<ITimeDto> => axios.patch(`${basePath}/stop`, payload),
  updateEventTimer: (payload: IEventTimeModelPayload): Promise<ITimeDto> => axios.patch(`${basePath}`, payload),
  deleteEventTimer: (payload: ITimeCancelParams): Promise<ITimeDto> => axios.patch(`${basePath}/cancel`, payload),
  pingEventTimer: (eventId: number): Promise<ITimeDto> => axios.patch(`${basePath}/ping`, { eventId }),
};
