import React from 'react';
import { Layout, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ERoutes } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const LoginPageComponent: React.FC<AllType> = ({ authModel, getAuthModelCode }) => {
  const { data: authModelData, loading } = authModel;

  const onLoginClick = () => {
    getAuthModelCode().then((response) => {
      location.href = response.url;
    });
  };

  if (authModelData) {
    return <Navigate to={ERoutes.Dashboard} />;
  }

  return (
    <Content className="login-page__container">
      <Spin spinning={loading}>
        <Button className="btn-primary" onClick={onLoginClick}>
          Sign in with Microsoft
        </Button>
      </Spin>
    </Content>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAuthModelCode: dispatch.authModel.getAuthModelCode,
});

export const LoginPage = connect(mapState, mapDispatch)(LoginPageComponent);
