import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { statisticsTransport } from 'entities/Statistics/Statistics.transport';
import {
  IDashboardStatisticsDto,
  IOperationStatisticDto,
  IStatisticsModel,
  IStatisticsPayload,
} from 'entities/Statistics/Statistics.models';

export const statisticsModel = createModel<IRootModel>()({
  state: {
    dashboardStatisticsData: null,
    operationsStatisticsData: null,
    loading: false,
  } as IStatisticsModel,
  reducers: {
    setDashboardStatisticsData: (state, payload: IDashboardStatisticsDto) => ({ ...state, dashboardStatisticsData: payload }),
    setOperationsStatisticsData: (state, payload: IOperationStatisticDto) => ({ ...state, operationsStatisticsData: payload }),
    setStatisticsModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getDashboardStatistics(params: IStatisticsPayload) {
      dispatch.statisticsModel.setStatisticsModelLoading(true);
      statisticsTransport
        .getDashboardStatistics(params)
        .then((response) => {
          dispatch.statisticsModel.setDashboardStatisticsData(response);
        })
        .finally(() => dispatch.statisticsModel.setStatisticsModelLoading(false));
    },
    async getOperationsStatistics() {
      dispatch.statisticsModel.setStatisticsModelLoading(true);
      statisticsTransport
        .getOperationsStatistics()
        .then((response) => {
          dispatch.statisticsModel.setOperationsStatisticsData(response);
        })
        .finally(() => dispatch.statisticsModel.setStatisticsModelLoading(false));
    },
  }),
});
