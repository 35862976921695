import { EOrderDirections, ESearchParams } from 'common/const/enums.const';

export const sort = (
  sorter: any,
  setSearchParam: (key: ESearchParams, value: string) => void,
  removeSearchParam: (key: ESearchParams) => void
) => {
  if (sorter.order) {
    setSearchParam(ESearchParams.OrderField, sorter.field);
    setSearchParam(ESearchParams.OrderDirection, sorter.order === 'ascend' ? EOrderDirections.ASC : EOrderDirections.DESC);
  } else {
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
  }
};
