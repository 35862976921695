import React from 'react';

interface IComponentProps {
  title: string;
  phone?: string;
  onClick: () => void;
}

export const Phone: React.FC<IComponentProps> = ({ title, phone, onClick }) => (
  <div className="phone">
    <p className="phone__number">
      {title}:{' '}
      <a href={`tel:${phone}`} onClick={onClick}>
        {phone}
      </a>
    </p>
  </div>
);
