import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { Timer } from 'entities/Timer/components/Timer';
import { getStopwatchOffset } from 'entities/Timer/Timer.helper';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
  canStart: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventTimerComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    autoStartTimer,
    canStart,
    eventTimerModel,
    getEventTimerModel,
    setEventTimerModelUntrackedTime,
    startEventTimer,
    stopEventTimer,
    setTimeEditorModalParams,
    updateEventTimer,
    setCommentModalParams,
  } = props;
  const getTimerModel = () => {
    return getEventTimerModel(eventId);
  };

  const onStartClick = () => {
    startEventTimer(eventId);
  };

  const handleCommentModalSend = (billingCycleId: string, comment?: string) => {
    stopEventTimer({ eventId, comment }).then(() => {
      setCommentModalParams({ open: false, externalId: null, edit: false });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null, edit: false });
  };

  const onStopClick = () => {
    setCommentModalParams({
      open: true,
      externalId: eventId,
      isOptionalComment: true,
      edit: true,
      onSendClick: handleCommentModalSend,
      onCancelClick: handleCommentModalCancel,
    });
  };

  const onCancelEditClick = () => {
    setTimeEditorModalParams({ open: false, externalId: null, timeResultId: null, hours: null, minutes: null, seconds: null });
  };

  const onSaveEditClick = (
    milliseconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void,
    comment: string
  ) => {
    updateEventTimer({ eventId, time: milliseconds, comment }).then(() => {
      reset(getStopwatchOffset(milliseconds), false);
      onCancelEditClick();
    });
  };

  const onEditClick = (
    hours: number,
    minutes: number,
    seconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void
  ) => {
    setTimeEditorModalParams({
      open: true,
      hours,
      minutes,
      seconds,
      reset,
      onSaveEditClick,
      onCancelEditClick,
    });
  };

  return (
    <Timer
      autoStartTimer={autoStartTimer || (canStart && eventTimerModel.data?.time?.status === 'active')}
      canStart={canStart}
      timerModel={eventTimerModel}
      getTimerModel={getTimerModel}
      setTimerModelUntrackedTime={setEventTimerModelUntrackedTime}
      onStartClick={onStartClick}
      onStopClick={onStopClick}
      onEditClick={onEditClick}
    />
  );
};

const mapState = (state: RootState) => ({
  eventTimerModel: state.eventTimerModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEventTimerModel: dispatch.eventTimerModel.getEventTimerModel,
  setEventTimerModelUntrackedTime: dispatch.eventTimerModel.setEventTimerModelUntrackedTime,
  startEventTimer: dispatch.eventTimerModel.startEventTimer,
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  setTimeEditorModalParams: dispatch.modals.setTimeEditorModalParams,
  updateEventTimer: dispatch.eventTimerModel.updateEventTimer,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
});

export const EventTimer = connect(mapState, mapDispatch)(EventTimerComponent);
