import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import { ERoutes } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AuthPageComponent: React.FC<AllType> = ({ authModel, addAuthModel, getAuthModelCodeVerifier }) => {
  const { data: authModelData, loading, error: authModelError } = authModel;
  const [params] = useSearchParams();
  const code = params.get('code');
  const state = params.get('state');
  const error = params.get('error');
  const hasError = error && error === 'access_denied';

  useEffect(() => {
    if (code && !hasError && !authModelData) {
      getAuthModelCodeVerifier().then((response) => {
        if (response) {
          addAuthModel({ code, codeVerifier: response as string, state: state ? state : undefined });
        }
      });
    }
  }, [authModelData, code, state, hasError]);

  if (hasError) {
    message.error('Whoops! Something went wrong! These credentials do not match our records.');
  }

  if (hasError || !code || authModelError) {
    return <Navigate to={ERoutes.Login} />;
  }

  if (authModelData) {
    return <Navigate to={ERoutes.Dashboard} />;
  }

  return <Spin className="login-page__container" size="large" spinning={loading} />;
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  addAuthModel: dispatch.authModel.addAuthModel,
  getAuthModelCodeVerifier: dispatch.authModel.getAuthModelCodeVerifier,
});

export const AuthPage = connect(mapState, mapDispatch)(AuthPageComponent);
