import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IEventsCollection, IEventsCollectionPayload, IEventsCollectionDto } from 'entities/Dashboard/Dashboard.models';
import { dashboardTransport } from 'entities/Dashboard/Dashboard.transport';

export const eventsCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IEventsCollection,
  reducers: {
    setEventsCollection: (state, payload: IEventsCollectionDto) => ({ ...state, data: payload }),
    setEventsCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getEventsCollection(params: IEventsCollectionPayload) {
      dispatch.eventsCollection.setEventsCollectionLoading(true);

      return dashboardTransport
        .getEventsCollection(params)
        .then((response) => {
          dispatch.eventsCollection.setEventsCollection(response);
          dispatch.usersCollection.getUsersCollection();
          dispatch.statisticsModel.getDashboardStatistics(params);
        })
        .finally(() => {
          dispatch.eventsCollection.setEventsCollectionLoading(false);
        });
    },
  }),
});
