import { EEventStatuses } from 'common/const/enums.const';

export const getEventAvailableOperations = (status?: string, currentUserId?: string, assigneeId?: string) => {
  let canBeMovedToInbox = false;
  let canBeCompleted = false;
  let canBeHeld = false;
  let canStart = false;
  let canBeOutdated = false;

  if (currentUserId && assigneeId && currentUserId === assigneeId && status === EEventStatuses.UnderReview) {
    canBeMovedToInbox = true;
    canBeCompleted = true;
    canBeHeld = true;
    canStart = true;
    canBeOutdated = true;
  }

  return { canBeMovedToInbox, canBeCompleted, canBeHeld, canStart, canBeOutdated: canBeOutdated };
};
