import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch } from 'app/store';
import { Phone } from 'entities/Phone/components/Phone';

interface IComponentProps {
  title: string;
  phone?: string;
  eventId: number;
  canStart: boolean;
  isRunning: boolean;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const EventPhoneComponent: React.FC<AllType> = (props) => {
  const {
    title,
    phone,
    eventId,
    canStart,
    isRunning,
    startEventTimer,
    setPhoneModelBlockRouter,
    setPhoneModelBlockIdle,
    addEventReviewCallHistory,
  } = props;

  const handleCallClick = () => {
    // When click on the link, a modal window is shown, need to block component EventRouterHandler
    setPhoneModelBlockRouter(false);
    // When click on the link, need to block component IdleTimer
    setPhoneModelBlockIdle(true);

    if (canStart && !isRunning) {
      startEventTimer(eventId).then(() => {
        addEventReviewCallHistory(eventId).then(() => {
          setPhoneModelBlockRouter(true);
        });
      });
    } else {
      addEventReviewCallHistory(eventId).then(() => {
        setPhoneModelBlockRouter(true);
      });
    }
  };

  return <Phone title={title} phone={phone} onClick={handleCallClick} />;
};

const mapDispatch = (dispatch: RootDispatch) => ({
  startEventTimer: dispatch.eventTimerModel.startEventTimer,
  setPhoneModelBlockRouter: dispatch.phoneModel.setPhoneModelBlockRouter,
  setPhoneModelBlockIdle: dispatch.phoneModel.setPhoneModelBlockIdle,
  addEventReviewCallHistory: dispatch.eventReviewHistoryCollection.addEventReviewCallHistory,
});

export const EventPhone = connect(null, mapDispatch)(EventPhoneComponent);
