import React from 'react';
import { EventActionsCard } from 'entities/Actions/components/EventActionsCard';
import { EventReviewHistory } from 'entities/ReviewHistory/components/EventReviewHistory';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
}

export const EventPanel: React.FC<IComponentProps> = ({ eventId, autoStartTimer }) => {
  return (
    <>
      <EventActionsCard eventId={eventId} autoStartTimer={autoStartTimer} />

      <EventReviewHistory eventId={eventId} />
    </>
  );
};
