import moment from 'moment';
import { ITimeDto } from 'entities/Timer/Timer.models';

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const convertToTimerFormat = (time?: number) => {
  const milliseconds = time ? Math.round(time / 1000) * 1000 : 0;
  const days = moment.duration(milliseconds).days();
  const hours = moment.duration(milliseconds).hours();
  const minutes = moment.duration(milliseconds).minutes();
  const seconds = moment.duration(milliseconds).seconds();

  return `${padTo2Digits(hours + days * 24)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const convertToMinutes = (milliseconds: number) => {
  return `${Math.round(milliseconds / 60000)} min`;
};

export const getBestTimeToCall = (time?: string) => {
  if (!time) {
    return '-';
  }

  if (time === 'morning') {
    return '9 - 11 am';
  } else if (time === 'afternoon') {
    return '11 am - 5 pm';
  } else {
    return '5 - 11 pm';
  }
};

export const getCurrentTimerValue = (time?: ITimeDto | null) => {
  if (!time) {
    return 0;
  }

  if (time.status === 'active') {
    return time.time + moment(time.pingedAt).diff(moment(time.startedAt));
  } else {
    return time.time;
  }
};
