import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  ICommentModalParams,
  IHoldModalParams,
  IInactiveModalParams,
  IOutdatedModalParams,
  ITimeEditorModalParams,
} from 'entities/Modals/Modals.models';

interface IState {
  commentModalParams: ICommentModalParams;
  timeEditorModalParams: ITimeEditorModalParams;
  inactiveModalParams: IInactiveModalParams;
  holdModalParams: IHoldModalParams;
  outdatedModalParams: IOutdatedModalParams;
}

export const modals = createModel<IRootModel>()({
  state: {
    commentModalParams: {
      open: false,
      isOptionalComment: false,
      externalId: null,
      edit: false,
    },
    timeEditorModalParams: {
      open: false,
      hours: null,
      minutes: null,
      seconds: null,
    },
    inactiveModalParams: {
      open: false,
      title: null,
      description: null,
    },
    holdModalParams: {
      open: false,
      defaultHoldUntil: null,
      defaultHoldReason: null,
    },
    outdatedModalParams: {
      open: false,
    },
  } as IState,
  reducers: {
    setCommentModalParams: (state, payload) => ({ ...state, commentModalParams: payload }),
    setTimeEditorModalParams: (state, payload) => ({ ...state, timeEditorModalParams: payload }),
    setInactiveModalParams: (state, payload) => ({ ...state, inactiveModalParams: payload }),
    setHoldModalParams: (state, payload) => ({ ...state, holdModalParams: payload }),
    setOutdatedModalParams: (state, payload) => ({ ...state, outdatedModalParams: payload }),
  },
  effects: (dispatch) => ({
    showEventInactiveModal(payload: IInactiveModalParams) {
      dispatch.modals.setCommentModalParams({ open: false, externalId: null, edit: false });
      dispatch.modals.setTimeEditorModalParams({
        open: false,
        hours: null,
        minutes: null,
        seconds: null,
      });
      dispatch.modals.setHoldModalParams({ open: false, defaultHoldUntil: null, defaultHoldReason: null });
      dispatch.modals.setOutdatedModalParams({ open: false });
      dispatch.modals.setInactiveModalParams(payload);
    },
  }),
});
