import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import { renderCarePlanRecords } from 'entities/CarePlan/CarePlan.helper';

interface IComponentProps {
  patientId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CarePlanComponent: React.FC<AllType> = ({ patientId, carePlanCollection, getCarePlanCollection }) => {
  const { data: carePlanCollectionData, loading } = carePlanCollection;
  const columns = renderCarePlanRecords();
  const dataSource = toDataSourseMapper(carePlanCollectionData || []);

  useEffect(() => {
    getCarePlanCollection({ patientId });
  }, []);

  if (!carePlanCollectionData) {
    return null;
  }

  return (
    <div>
      <div className="subtitle">Care Plan History</div>

      <Table columns={columns} dataSource={dataSource} loading={loading} pagination={false} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  carePlanCollection: state.carePlanCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCarePlanCollection: dispatch.carePlanCollection.getCarePlanCollection,
});

export const CarePlan = connect(mapState, mapDispatch)(CarePlanComponent);
