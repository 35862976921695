import axios from 'axios';
import {
  IEventHistoryCollectionDto,
  IEventReviewCommentHistoryPayload,
  IEventReviewUpdatePayload,
} from 'entities/ReviewHistory/ReviewHistory.models';

const basePath = 'events/history';

export const reviewHistoryTransport = {
  getEventReviewHistoryCollection: (eventId: number): Promise<IEventHistoryCollectionDto> => axios.get(`${basePath}/${eventId}`),
  addEventReviewCallHistory: (eventId: number): Promise<void> => axios.post(`${basePath}/call/${eventId}`),
  addEventReviewCommentHistory: ({ eventId, ...restPayload }: IEventReviewCommentHistoryPayload): Promise<void> =>
    axios.post(`${basePath}/comment/${eventId}`, restPayload),
  updateEventReviewCommentHistory: (payload: IEventReviewUpdatePayload): Promise<void> => axios.patch(`${basePath}`, payload),
};
