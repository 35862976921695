import React from 'react';
import { Menu as AntdMenu } from 'antd';
import type { MenuProps } from 'antd';
import { IMenuItem } from 'common/models';

interface IComponentProps {
  items: IMenuItem[];
  selectedKeys: string[];
  onChange: (key: string) => void;
}

export const Menu: React.FC<IComponentProps> = ({ items, selectedKeys, onChange }) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onChange(e.key.toString());
  };

  return (
    <AntdMenu className="filter__menu" items={items} selectedKeys={selectedKeys} onClick={handleMenuClick} mode="horizontal" />
  );
};
