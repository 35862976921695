import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { renderRetrievalRecords } from 'entities/Retrieval/Retrieval.helper';
import { retrievalTransport } from 'entities/Retrieval/Retrieval.transport';
import { IRetrievalItem } from 'entities/Retrieval/Retrieval.models';

interface IComponentProps {
  patientId: string;
}

const RetrievalComponent: React.FC<IComponentProps> = ({ patientId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [retrievals, setRetrievals] = useState<IRetrievalItem[]>([]);

  const columns = renderRetrievalRecords();
  const dataSource = toDataSourseMapper(retrievals);

  useEffect(() => {
    retrievalTransport
      .getRetrievalCollection({ patientId })
      .then(({ data }) => {
        setRetrievals(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <div className="subtitle">Retrieval History</div>

      <Table columns={columns} dataSource={dataSource} loading={isLoading} pagination={false} />
    </div>
  );
};

export const Retrieval = RetrievalComponent;
