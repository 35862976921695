import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IActionParams } from 'entities/Actions/Actions.models';

export const actionModel = createModel<IRootModel>()({
  state: {
    actionParams: {
      type: '',
    } as IActionParams,
    loading: false,
  },
  reducers: {
    setActionParams: (state, payload: IActionParams) => ({ ...state, actionParams: payload }),
    setLoading: (state, paylod: boolean) => ({ ...state, loading: paylod }),
  },
});
