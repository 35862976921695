import { EEventStatuses, EEventStatusTitles, EEventTypesShort } from 'common/const/enums.const';

export const eventsMenuItems = [
  {
    label: EEventStatusTitles.inbox,
    key: EEventStatuses.Inbox,
  },
  {
    label: EEventStatusTitles.under_review,
    key: EEventStatuses.UnderReview,
  },
  {
    label: EEventStatusTitles.on_hold,
    key: EEventStatuses.OnHold,
  },
  {
    label: EEventStatusTitles.completed,
    key: EEventStatuses.Completed,
  },
  {
    label: EEventStatusTitles.outdated,
    key: EEventStatuses.Outdated,
  },
];

export const eventTypesList = Object.values(EEventTypesShort).map((item) => ({ value: item, label: item }));
