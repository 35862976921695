import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, DatePicker, Input, Modal, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { EDateFormat, EHoldReason, EHoldReasonLabel } from 'common/const/enums.const';
import { IOption } from 'common/models';
import { RootState } from 'app/store';
import { disabledHoldDate, getHoldModalReasonOptions } from 'entities/Modals/Modals.helper';

type AllType = ReturnType<typeof mapState>;

const HoldModalComponent: React.FC<AllType> = ({ holdModalParams, loading }) => {
  const { open, defaultHoldUntil, defaultHoldReason, onSaveClick, onCancelClick, onReleaseClick } = holdModalParams;
  const [holdUntil, setHoldUntil] = useState<Moment | null>(null);
  const [holdReason, setHoldReason] = useState<IOption | null>(null);
  const [comment, setComment] = useState<string>();

  const options = getHoldModalReasonOptions();

  const onHoldUntilChange = (value: Moment | null) => {
    setHoldUntil(value);
  };

  const onHoldReasonChange = (value: IOption) => {
    setHoldReason(value);
  };

  const handleCancelClick = () => {
    setHoldUntil(null);
    setHoldReason(null);
    onCancelClick?.();
    setComment(undefined);
  };

  const handleSaveClick = () => {
    if (holdUntil && holdReason) {
      onSaveClick?.(holdUntil.format(EDateFormat.FullYearReverse), holdReason.value as EHoldReason, comment);
      setHoldUntil(null);
      setHoldReason(null);
      setComment(undefined);
    }
  };

  const handleReleaseClick = () => {
    onReleaseClick?.();
    setHoldUntil(null);
    setHoldReason(null);
    setComment(undefined);
  };

  const onCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    value.length ? setComment(value) : setComment(undefined);
  };

  useEffect(() => {
    if (defaultHoldUntil && defaultHoldReason) {
      setHoldUntil(moment(defaultHoldUntil));
      setHoldReason({ label: EHoldReasonLabel[defaultHoldReason], value: defaultHoldReason });
    }
  }, [defaultHoldUntil, defaultHoldReason]);

  return (
    <Modal
      className="hold-modal"
      title="Hold"
      open={open}
      footer={false}
      onCancel={handleCancelClick}
      width={DEFAULT_MODAL_WIDTH}
    >
      <Spin spinning={loading}>
        <div className="modal__body">
          <DatePicker
            popupClassName="hold-modal__date-picker"
            value={holdUntil}
            onChange={onHoldUntilChange}
            placeholder="Release date"
            format={EDateFormat.FullYear}
            suffixIcon={<div className="icon datepicker-suffix" />}
            disabledDate={disabledHoldDate}
          />

          <Select
            popupClassName="hold-modal__select-dropdown"
            labelInValue
            placeholder="Reason"
            value={holdReason}
            onChange={onHoldReasonChange}
            options={options}
          />

          <Input.TextArea value={comment} onChange={onCommentChange} placeholder="Note" rows={4} />
        </div>

        <div className="hold-modal__footer">
          <div className="hold-modal__footer-container">
            <Button className="btn-green" onClick={handleSaveClick} disabled={!holdUntil || !holdReason}>
              Save
            </Button>

            <Button onClick={handleCancelClick}>Cancel</Button>
          </div>

          {defaultHoldUntil && defaultHoldReason && (
            <Button className="btn-red" onClick={handleReleaseClick}>
              Release
            </Button>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  holdModalParams: state.modals.holdModalParams,
  loading: state.eventModel.loading,
});

export const HoldModal = connect(mapState)(HoldModalComponent);
