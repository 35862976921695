import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { timerTransport } from 'entities/Timer/Timer.transport';
import {
  IEventTimerModel,
  IEventTimeModelPayload,
  ITimeStopParams,
  ITimeCancelParams,
  ITimeInfoDto,
} from 'entities/Timer/Timer.models';

let timerId: NodeJS.Timer;

export const eventTimerModel = createModel<IRootModel>()({
  state: {
    data: null,
    isRunning: false,
    untrackedTime: 0,
    loading: false,
  } as IEventTimerModel,
  reducers: {
    setEventTimerModel: (state, payload: ITimeInfoDto) => ({ ...state, data: payload }),
    setEventTimerModelIsRunning: (state, payload: boolean) => ({ ...state, isRunning: payload }),
    setEventTimerModelUntrackedTime: (state, untrackedTime: number) => ({ ...state, untrackedTime }),
    setEventTimerModelLoading: (state, loading: boolean) => ({ ...state, loading }),
    clearEventTimerModel: (state) => ({ ...state, data: null, untrackedTime: 0, overallSpentTime: 0 }),
  },
  effects: (dispatch) => ({
    async getEventTimerModel(eventId: number) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .getEventTimerModel(eventId)
        .then((response) => {
          dispatch.eventTimerModel.setEventTimerModel(response);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async startEventTimer(eventId) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      return await timerTransport
        .startEventTimer(eventId)
        .then(() => {
          dispatch.eventTimerModel.setEventTimerModelIsRunning(true);
          dispatch.eventTimerModel.pingEventTimer(eventId);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async stopEventTimer(payload: ITimeStopParams) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .stopEventTimer(payload)
        .then((response) => {
          dispatch.eventTimerModel.setEventTimerModel({ time: response });
          dispatch.eventTimerModel.setEventTimerModelIsRunning(false);
          dispatch.eventTimerModel.clearPingEventTimer();
          dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection(payload.eventId);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async updateEventTimer(payload: IEventTimeModelPayload) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .updateEventTimer(payload)
        .then((response) => {
          dispatch.eventTimerModel.setEventTimerModel({ time: response });
          dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection(payload.eventId);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async deleteEventTimer(payload: ITimeCancelParams) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .deleteEventTimer(payload)
        .then(() => {
          dispatch.eventTimerModel.setEventTimerModelIsRunning(false);
          dispatch.eventTimerModel.clearPingEventTimer();
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async pingEventTimer(eventId: number) {
      timerId = setInterval(() => {
        timerTransport.pingEventTimer(eventId);
      }, 10000);
    },
    clearPingEventTimer() {
      clearInterval(timerId);
    },
  }),
});
