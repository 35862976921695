import { ITaskItem } from 'entities/Tasks/Tasks.models';
import { eventTasksOptions } from 'entities/Tasks/Tasks.const';

export const eventTasksToDataMapper = (tasks: ITaskItem[]) => {
  return tasks.map((task) => ({
    id: task.id,
    name: task.name,
    status: task.status,
    options: eventTasksOptions,
  }));
};
