import { DEFAULT_PAGE_SIZE } from 'common/config';
import { IEventsCollectionPayload } from 'entities/Dashboard/Dashboard.models';

export const eventsQueryParamsToPayloadMapper = (
  params: IEventsCollectionPayload,
  pageId: number,
  currentUid?: number,
  currentUserRole?: string
) => {
  let assigneeId;

  if (currentUserRole === 'pcc' && !params.assigneeId) {
    assigneeId = currentUid;
  }

  // -1 - id for the All PCC Queue option
  if (params.assigneeId && params.assigneeId !== -1) {
    assigneeId = params.assigneeId;
  }

  return {
    ...params,
    assigneeId,
    offset: DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: DEFAULT_PAGE_SIZE,
  };
};
