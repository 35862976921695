import React from 'react';
import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { getTimeFromZone } from 'common/helpers/timezone.helper';
import { getBestTimeToCall } from 'common/helpers/time.helper';
import { ReactComponent as OpenIcon } from 'app/assets/images/svg/open-icon.svg';
import { PatientDetailItem } from 'entities/Patient/components/PatientDetailsItem';
import { IPatientDetails } from 'entities/Patient/Patient.models';

interface IComponentProps {
  details: IPatientDetails;
}

export const PatientDetails: React.FC<IComponentProps> = ({ details }) => {
  const {
    gender,
    dob,
    lastFour,
    language,
    patientLocalTime,
    bestTimeToCall,
    patientDetailLink,
    patientDetailErpLink,
    retrievalsLink,
  } = details;
  const age = moment().diff(moment(dob).format(EDateFormat.FullYear), 'years');

  return (
    <div className="patient-details__common">
      <div className="patient-details__info">
        <PatientDetailItem label="Gender" value={gender} />
        <PatientDetailItem label="Age" value={age} />
        <PatientDetailItem label="DOB" value={moment(dob).format(EDateFormat.FullYear)} />
        <PatientDetailItem label="Last four" value={lastFour} />
        {language && <PatientDetailItem label="Language" value={language} />}
        {patientLocalTime && <PatientDetailItem label="Patients Local Time" value={getTimeFromZone(patientLocalTime)} />}
        {bestTimeToCall && <PatientDetailItem label="Best Time to Call" value={getBestTimeToCall(bestTimeToCall)} />}
      </div>

      <div className="patient-details__links">
        {patientDetailLink && (
          <a className="patient-details__link" onClick={() => window.open(patientDetailLink, '_blank')}>
            <span>Celeri Patient Detail</span>
            <OpenIcon />
          </a>
        )}
        {patientDetailErpLink && (
          <a className="patient-details__link" onClick={() => window.open(patientDetailErpLink, '_blank')}>
            <span>ERP Link to Patient Detail</span>
            <OpenIcon />
          </a>
        )}
        {retrievalsLink && (
          <a className="patient-details__link" onClick={() => window.open(retrievalsLink, '_blank')}>
            <span>To Retrieval</span>
            <OpenIcon />
          </a>
        )}
      </div>
    </div>
  );
};
