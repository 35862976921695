import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { tasksTransport } from 'entities/Tasks/Tasks.transport';
import { ITasksCollection, ITaskItem, ITaskChangePayload } from 'entities/Tasks/Tasks.models';

export const tasksCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ITasksCollection,
  reducers: {
    setTasksCollection: (state, payload: ITaskItem[]) => ({ ...state, data: payload }),
    setTasksCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearTasksCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async changeEventTaskStatus(payload: ITaskChangePayload, models) {
      dispatch.tasksCollection.setTasksCollectionLoading(true);
      await tasksTransport
        .changeEventTaskStatus(payload)
        .then((response) => {
          const task = models.tasksCollection.data?.find((item) => item.id === response.id);

          if (task) {
            task.status = response.status;
            dispatch.tasksCollection.setTasksCollection(models.tasksCollection.data as ITaskItem[]);
          }
        })
        .finally(() => {
          dispatch.tasksCollection.setTasksCollectionLoading(false);
        });
    },
  }),
});
