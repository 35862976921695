import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { IRetrievalItem } from 'entities/Retrieval/Retrieval.models';

export const renderRetrievalRecords = () => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <a onClick={() => window.open(record.link, '_blank')}>{record.id}</a>,
    },
    {
      title: 'PCC Assignment',
      dataIndex: 'pccName',
      key: 'pccName',
      render: (text) => text,
    },
    {
      title: 'Sales Manager',
      dataIndex: 'salesManagerName',
      key: 'salesManagerName',
      render: (text) => text,
    },
    {
      title: 'Regenesis Account Number',
      dataIndex: 'regenesisAccountNumber',
      key: 'regenesisAccountNumber',
      render: (text) => text,
    },
    {
      title: 'Device Type',
      dataIndex: 'deviceType',
      key: 'deviceType',
      render: (text) => text,
    },
    {
      title: 'Return Reason',
      dataIndex: 'returnReason',
      key: 'returnReason',
      render: (text) => text,
    },
    {
      title: 'Last Contact Date',
      dataIndex: 'lastContactDate',
      key: 'lastContactDate',
      render: (text) => text,
    },
    {
      title: 'CPU Number',
      dataIndex: 'cpuNumber',
      key: 'cpuNumber',
      render: (text) => text,
    },
    {
      title: 'Return Link',
      dataIndex: 'returnLink',
      key: 'returnLink',
      render: (text) => (text ? <a onClick={() => window.open(text, '_blank')}>Return Link</a> : '-'),
    },
    {
      title: 'Additional Return Link',
      dataIndex: 'additionalReturnLink',
      key: 'additionalReturnLink',
      render: (text) => (text ? <a onClick={() => window.open(text, '_blank')}>Additional Return Link</a> : '-'),
    },
  ] as ColumnsType<IRetrievalItem>;
};
