import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { RouterPrompt } from 'common/components/RouterPrompt';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { ITransition } from 'common/models';
import { EActionTypes, EEventStatuses } from 'common/const/enums.const';
import { useBlocker } from 'common/hooks/useBlocker';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  eventId: number;
  canStart: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventRouterHandlerComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    canStart,
    isRunning,
    untrackedTime,
    actionParams,
    stopEventTimer,
    deleteEventTimer,
    changeEventStatus,
    showEventInactiveModal,
    setHoldModalParams,
    setOutdatedModalParams,
  } = props;

  const blocker = useCallback(
    (autoUnblockingTx: ITransition) => {
      const { type, holdUntil, holdReason, comment, outdatedReason } = actionParams;

      switch (true) {
        case type === EActionTypes.Complete: {
          changeEventStatus({ eventId, status: EEventStatuses.Completed }).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case type === EActionTypes.Outdated: {
          setOutdatedModalParams({ open: false });
          changeEventStatus({ eventId, status: EEventStatuses.Outdated, outdatedReason }).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case type === EActionTypes.Hold: {
          setHoldModalParams({ open: false, defaultHoldUntil: null, defaultHoldReason: null });
          changeEventStatus({ eventId, status: EEventStatuses.OnHold, holdUntil, holdReason, comment }).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case canStart && autoUnblockingTx.action === 'PUSH':
        case canStart && autoUnblockingTx.action === 'POP' && !type:
        case canStart && type === EActionTypes.Back:
        case type === EActionTypes.Move: {
          showEventInactiveModal({ open: false, title: null, description: null });
          changeEventStatus({ eventId, status: EEventStatuses.Inbox }).then(() => {
            autoUnblockingTx.retry();
          });

          break;
        }
        default: {
          autoUnblockingTx.retry();
          break;
        }
      }
    },
    [canStart, actionParams.type]
  );

  useBlocker(canStart && !isRunning, blocker);

  const renderPrompt = (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => {
    return (
      isActive && (
        <Modal title="New Notification" open={true} footer={false} onCancel={onCancel} width={DEFAULT_MODAL_WIDTH}>
          <div className="modal__body">
            <p>
              From the review start you’ve spent {convertToTimerFormat(untrackedTime)}, but haven’t stopped the timer. Save time
              or delete?
            </p>
          </div>
          <div className="modal__footer">
            <Button
              className="btn-primary"
              onClick={() => {
                stopEventTimer({ eventId }).then(() => {
                  onConfirm?.(undefined);
                });
              }}
            >
              Save and Leave
            </Button>
            <Button
              className="btn-orange"
              onClick={() => {
                deleteEventTimer({ eventId }).then(() => {
                  onConfirm?.(undefined);
                });
              }}
            >
              Delete and Leave
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Modal>
      )
    );
  };

  return <RouterPrompt when={isRunning} renderPrompt={renderPrompt} />;
};

const mapState = (state: RootState) => ({
  isRunning: state.eventTimerModel.isRunning,
  untrackedTime: state.eventTimerModel.untrackedTime,
  actionParams: state.actionModel.actionParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  deleteEventTimer: dispatch.eventTimerModel.deleteEventTimer,
  changeEventStatus: dispatch.eventModel.changeEventStatus,
  showEventInactiveModal: dispatch.modals.showEventInactiveModal,
  setHoldModalParams: dispatch.modals.setHoldModalParams,
  setOutdatedModalParams: dispatch.modals.setOutdatedModalParams,
});

export const EventRouterHandler = connect(mapState, mapDispatch)(EventRouterHandlerComponent);
