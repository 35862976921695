import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { ICarePlanCollection, ICarePlanItem, ICarePlanCollectionPayload } from 'entities/CarePlan/CarePlan.models';
import { carePlanTransport } from 'entities/CarePlan/CarePlan.transport';

export const carePlanCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICarePlanCollection,
  reducers: {
    setCarePlanCollection: (state, payload: ICarePlanItem[]) => ({ ...state, data: payload }),
    setCarePlanCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCarePlanCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getCarePlanCollection(payload: ICarePlanCollectionPayload) {
      dispatch.carePlanCollection.setCarePlanCollectionLoading(true);
      carePlanTransport
        .getCarePlanCollection(payload)
        .then((response) => {
          dispatch.carePlanCollection.setCarePlanCollection(response);
        })
        .finally(() => {
          dispatch.carePlanCollection.setCarePlanCollectionLoading(false);
        });
    },
  }),
});
