import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { reviewHistoryTransport } from 'entities/ReviewHistory/ReviewHistory.transport';
import {
  IEventReviewHistoryCollection,
  IEventHistoryCollectionDto,
  IEventReviewCommentHistoryPayload,
  IEventReviewUpdatePayload,
} from 'entities/ReviewHistory/ReviewHistory.models';

export const eventReviewHistoryCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IEventReviewHistoryCollection,
  reducers: {
    setEventReviewHistoryCollection: (state, payload: IEventHistoryCollectionDto) => ({ ...state, data: payload }),
    setEventReviewHistoryCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEventReviewHistoryCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEventReviewHistoryCollection(eventId: number) {
      dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(true);
      reviewHistoryTransport
        .getEventReviewHistoryCollection(eventId)
        .then((response) => {
          dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollection(response);
        })
        .finally(() => {
          dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(false);
        });
    },
    async addEventReviewCallHistory(eventId: number) {
      dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(true);
      return reviewHistoryTransport
        .addEventReviewCallHistory(eventId)
        .then(() => {
          dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection(eventId);
        })
        .finally(() => {
          dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(false);
        });
    },
    async addEventReviewCommentHistory(payload: IEventReviewCommentHistoryPayload) {
      dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(true);
      return reviewHistoryTransport
        .addEventReviewCommentHistory(payload)
        .then(() => {
          dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection(payload.eventId);
        })
        .finally(() => {
          dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(false);
        });
    },
    async updateEventReviewCommentHistory(payload: IEventReviewUpdatePayload) {
      dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(true);
      return reviewHistoryTransport
        .updateEventReviewCommentHistory(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .finally(() => {
          dispatch.eventReviewHistoryCollection.setEventReviewHistoryCollectionLoading(false);
        });
    },
  }),
});
