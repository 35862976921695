import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { ERoutes } from 'common/const/enums.const';
import { RootState } from 'app/store';
import { Dashboard } from 'entities/Dashboard/components/Dashboard';
import { Operations } from 'entities/Operations/components/Operations';
import { Event } from 'entities/Event/components/Event';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState>;

const PrivateLayoutComponent: React.FC<AllType> = ({ authModel }) => {
  const { data, loading } = authModel;
  const authorized = data?.access?.token;

  if (!authorized && !loading) {
    return <Navigate to={ERoutes.Login} />;
  }

  if (!authorized && loading) {
    return null;
  }

  return (
    <Content>
      <Routes>
        <Route path={ERoutes.Dashboard} element={<Dashboard />} />
        <Route path={ERoutes.Operations} element={<Operations />} />
        <Route path={ERoutes.Event} element={<Event />} />
        <Route path="*" element={<Navigate to={ERoutes.Dashboard} replace />} />
      </Routes>
    </Content>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});

export const PrivateLayout = connect(mapState)(PrivateLayoutComponent);
