import React from 'react';
import type { MenuProps } from 'antd';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ENavOptions, ERoutes } from 'common/const/enums.const';
import { RootDispatch, RootState, history } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const HeaderComponent: React.FC<AllType> = ({ authModel, eventModel, isRunning, stopEventTimer, deleteAuthModel }) => {
  const { data: authModelData } = authModel;
  const { data: eventModelData } = eventModel;
  const authorized = authModelData?.access?.token;
  const currentUserName = authModelData?.access?.id;
  const eventId = eventModelData?.id;
  const location = useLocation();
  const autoStartTimer = new URLSearchParams(window.location.search).get('autoStartTimer') === 'true';

  const navItems: MenuProps['items'] = [
    {
      label: <Link to={ERoutes.Dashboard}>{ENavOptions.Dashboard}</Link>,
      key: ERoutes.Dashboard,
    },
    {
      label: <Link to={ERoutes.Operations}>{ENavOptions.Operations}</Link>,
      key: ERoutes.Operations,
    },
  ];

  const logout = () => {
    if (autoStartTimer && isRunning && eventId) {
      stopEventTimer({ eventId }).then(() => {
        history.push(ERoutes.Login);
        deleteAuthModel();
      });
    } else {
      history.push(ERoutes.Login);
      deleteAuthModel();
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div className="header__menu-item">
              Log out
              <div className="header__logout-icon" />
            </div>
          ),
          key: 'logout',
          onClick: logout,
        },
      ]}
    />
  );

  return (
    <div className="header">
      <div className="header__icon"></div>
      {authorized && (
        <div className="header__nav">
          <Menu selectedKeys={[location.pathname]} mode="horizontal" items={navItems} />
          <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {currentUserName}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventModel: state.eventModel,
  isRunning: state.eventTimerModel.isRunning,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  deleteAuthModel: dispatch.authModel.deleteAuthModel,
});

export const Header = connect(mapState, mapDispatch)(HeaderComponent);
