import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { EHoldReason, EHoldReasonLabel, EOutdatedReasonLabel } from 'common/const/enums.const';

export const getHoldModalReasonOptions = () => {
  return Object.values(EHoldReason).map((item) => ({ label: EHoldReasonLabel[item], value: item }));
};

export const getOutdatedModalReasonOptions = () => {
  return Object.values(EOutdatedReasonLabel).map((item) => ({ label: item, value: item }));
};

export const disabledHoldDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < moment().endOf('day');
};
