import axios from 'axios';
import { IDashboardStatisticsDto, IOperationStatisticDto, IStatisticsPayload } from 'entities/Statistics/Statistics.models';

const basePath = 'statistics';
const operationsPath = 'operations/statistic';

export const statisticsTransport = {
  getDashboardStatistics: (params: IStatisticsPayload): Promise<IDashboardStatisticsDto> => axios.get(`${basePath}`, { params }),
  getOperationsStatistics: (): Promise<IOperationStatisticDto> => axios.get(`${operationsPath}`),
};
