import { init, RematchRootState, RematchDispatch, Models } from '@rematch/core';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { authModel } from 'entities/Auth/Auth.communication';
import { eventsCollection } from 'entities/Dashboard/Dashboard.communication';
import { statisticsModel } from 'entities/Statistics/Statistics.communication';
import { operationsCollection } from 'entities/Operations/Operations.communication';
import { modals } from 'entities/Modals/Modals.communication';
import { usersCollection } from 'entities/Users/Users.communication';
import { eventModel } from 'entities/Event/Event.communication';
import { actionModel } from 'entities/Actions/Actions.communication';
import { patientModel } from 'entities/Patient/Patient.communication';
import { carePlanCollection } from 'entities/CarePlan/CarePlan.communication';
import { eventReviewHistoryCollection } from 'entities/ReviewHistory/ReviewHistory.communication';
import { eventTimerModel } from 'entities/Timer/Timer.communication';
import { tasksCollection } from 'entities/Tasks/Tasks.communication';
import { phoneModel } from 'entities/Phone/Phone.communication';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = { router: routerReducer };

export interface IRootModel extends Models<IRootModel> {
  authModel: typeof authModel;
  eventsCollection: typeof eventsCollection;
  statisticsModel: typeof statisticsModel;
  operationsCollection: typeof operationsCollection;
  modals: typeof modals;
  usersCollection: typeof usersCollection;
  eventModel: typeof eventModel;
  actionModel: typeof actionModel;
  patientModel: typeof patientModel;
  carePlanCollection: typeof carePlanCollection;
  eventReviewHistoryCollection: typeof eventReviewHistoryCollection;
  eventTimerModel: typeof eventTimerModel;
  tasksCollection: typeof tasksCollection;
  phoneModel: typeof phoneModel;
}

const models = {
  authModel,
  eventsCollection,
  statisticsModel,
  operationsCollection,
  modals,
  usersCollection,
  eventModel,
  actionModel,
  patientModel,
  carePlanCollection,
  eventReviewHistoryCollection,
  eventTimerModel,
  tasksCollection,
  phoneModel,
} as IRootModel;

const store = init<IRootModel>({
  redux: {
    reducers,
    middlewares: [routerMiddleware],
    devtoolOptions: {},
  },
  models,
});

export default store;
export const history = createReduxHistory(store);
export type RootState = RematchRootState<IRootModel>;
export type RootDispatch = RematchDispatch<IRootModel>;
