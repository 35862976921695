import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { SearchInput } from 'common/components/SearchInput';
import { DatePicker } from 'common/components/DatePicker';
import { ESearchParams } from 'common/const/enums.const';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { queryParamsToPayloadMapper, toDataSourseMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import { getOperationsSearchParams, renderOperationsRecords } from 'entities/Operations/Operations.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Operations: React.FC<AllType> = (props) => {
  const { operationsCollection, getOperationsCollection, getOperationsReport } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const { data: operationsData, loading } = operationsCollection;

  const params = getOperationsSearchParams(searchParams);
  const dataSource = toDataSourseMapper(operationsData?.data);
  const columns = renderOperationsRecords();

  const { search, dateFrom, dateTo, orderField, orderDirection } = params;

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const onExportClick = () => {
    getOperationsReport({
      search,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      orderField,
      orderDirection,
    } as any);
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  useEffect(() => {
    const payload = queryParamsToPayloadMapper(params, pageId);

    getOperationsCollection(payload);
  }, [search, dateFrom, dateTo, orderField, orderDirection]);

  return (
    <>
      <div className="filter">
        <div className="filter__row">
          <SearchInput value={search} onChange={searchValueChange} placeholder="Staff member" />

          <DatePicker dateFrom={dateFrom} dateTo={dateTo} onChange={dateRangeValueChange} />
        </div>

        <div className="filter__row">
          <div className="filter__btn_wrapper">
            <Button onClick={onExportClick}>Export to CSV</Button>
          </div>
        </div>
      </div>

      <Table columns={columns} dataSource={dataSource} pagination={false} size="small" loading={loading} onChange={handleSort} />

      <Pagination
        current={pageId}
        total={operationsData?.meta.count}
        showSizeChanger={false}
        onChange={(page: number) => setPageId(page)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  operationsCollection: state.operationsCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getOperationsCollection: dispatch.operationsCollection.getOperationsCollection,
  getOperationsReport: dispatch.operationsCollection.getOperationsReport,
});

export default connect(mapState, mapDispatch)(Operations);
