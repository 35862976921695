import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  IEventModel,
  IEventModelDto,
  IEventModelChangeStatusPayload,
  IEventChangeUnableToContactPayload,
} from 'entities/Event/Event.models';
import { eventTransport } from 'entities/Event/Event.transport';
import { eventTasksToDataMapper } from 'entities/Tasks/Tasks.mapper';

export const eventModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IEventModel,
  reducers: {
    setEventModel: (state, payload: IEventModelDto) => ({ ...state, data: payload }),
    setEventModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEventModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEventById(eventId: number) {
      dispatch.eventModel.setEventModelLoading(true);
      eventTransport
        .getEventById(eventId)
        .then((response) => {
          dispatch.patientModel.getPatientModel(eventId).then(() => {
            const tasksCollection = eventTasksToDataMapper(response.tasks);

            dispatch.eventModel.setEventModel(response);
            dispatch.tasksCollection.setTasksCollection(tasksCollection);
          });
        })
        .finally(() => {
          dispatch.eventModel.setEventModelLoading(false);
        });
    },
    async changeEventStatus(payload: IEventModelChangeStatusPayload) {
      dispatch.eventModel.setEventModelLoading(true);
      return eventTransport
        .changeEventStatus(payload)
        .then((response) => {
          dispatch.eventModel.setEventModel(response);
        })
        .finally(() => {
          dispatch.eventModel.setEventModelLoading(false);
        });
    },
    async changeEventUnableToContact(payload: IEventChangeUnableToContactPayload) {
      dispatch.eventModel.setEventModelLoading(true);

      await eventTransport
        .changeEventUnableToContact(payload)
        .then((response) => {
          dispatch.eventModel.setEventModel(response);
          dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection(payload.eventId);
        })
        .finally(() => {
          dispatch.eventModel.setEventModelLoading(false);
        });
    },
    clearModels() {
      dispatch.tasksCollection.clearTasksCollection();
      dispatch.eventTimerModel.clearEventTimerModel();
      dispatch.eventReviewHistoryCollection.clearEventReviewHistoryCollection();
      dispatch.carePlanCollection.clearCarePlanCollection();
      dispatch.patientModel.clearPatientModel();
      dispatch.eventModel.clearEventModel();
      dispatch.phoneModel.setPhoneModelBlockIdle(false);
    },
  }),
});
