import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import MagicUrl from 'quill-magic-url';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { RootState } from 'app/store';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/magicUrl', MagicUrl);

type AllType = ReturnType<typeof mapState>;

const CommentModal: React.FC<AllType> = ({ commentModalParams, loading, timerLoading }) => {
  const { open, externalId, edit, defaultValue, onSendClick, onCancelClick, isOptionalComment } = commentModalParams;
  const [text, setText] = useState<string>();
  const textareaRef = useAutoFocus();

  const isSendDisabled = !isOptionalComment && !text?.length;

  const handleCancelClick = () => {
    onCancelClick?.();
    setText(undefined);
  };

  const handleSendClick = () => {
    if (externalId) {
      onSendClick?.(externalId, text);
      setText(undefined);
    }
  };

  const modules = {
    magicUrl: true,
  };

  useEffect(() => {
    if (defaultValue) {
      setText(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Modal
      className="comment-modal"
      title="Leave a comment"
      open={open}
      footer={false}
      onCancel={handleCancelClick}
      width={DEFAULT_MODAL_WIDTH}
    >
      <Spin spinning={loading || timerLoading}>
        <div className="modal__body">
          {edit ? (
            <Input.TextArea
              placeholder="Message"
              rows={6}
              value={text}
              onChange={(e) => setText(e.target.value)}
              ref={textareaRef}
              autoFocus
            />
          ) : (
            <ReactQuill placeholder="Message" theme="snow" value={text} onChange={(value) => setText(value)} modules={modules} />
          )}
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={handleSendClick} disabled={isSendDisabled}>
            Send
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  commentModalParams: state.modals.commentModalParams,
  loading: state.eventReviewHistoryCollection.loading,
  timerLoading: state.eventTimerModel.loading,
});

export default connect(mapState)(CommentModal);
