import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { createModel } from '@rematch/core';
import { message } from 'antd';
import { SocketIOService } from 'common/SocketIoService';
import { IRootModel } from 'app/store';
import { IAuthModel, IAuthModelPayload, ITokenDto } from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';

export const authModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
    error: false,
  } as IAuthModel,
  reducers: {
    setAuthModel: (state, payload: { data: ITokenDto | null; error?: boolean }) => ({
      ...state,
      data: payload.data,
      error: payload.error,
    }),
    setAuthModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearAuthModel: (state) => ({ ...state, data: null, loading: false, error: false }),
  },
  effects: (dispatch) => ({
    async initAuthModel() {
      dispatch.authModel.setAuthModelLoading(true);
      await getCreds()
        .then((response: ITokenDto) => {
          if (Object.values(response).length) {
            dispatch.authModel.setAuthModel({ data: response });
            SocketIOService.connect(response.access?.token as string);
          } else {
            dispatch.authModel.clearAuthModel();
          }
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
    async addAuthModel(payload: IAuthModelPayload) {
      await authTransport
        .addAuthModel(payload)
        .then((response) => {
          const creds = {
            access: {
              id: response.name,
              token: response.access_token,
              uid: response.uid,
              userId: response.id,
              issuedAt: Number(response.expires_in),
              expiredAt: Number(response.expires_in),
              role: response.rights[0],
            },
            refresh: {
              id: response.name,
              token: String(response.refresh_token),
              userId: response.id,
              issuedAt: Number(response.expires_in),
              expiredAt: Number(response.expires_in),
            },
          };
          saveCreds(creds);
          dispatch.authModel.setAuthModel({ data: creds, error: false });
          SocketIOService.connect(creds.access.token);
        })
        .catch((response) => {
          console.error(response);
          message.error(response.response.data.error);
          dispatch.authModel.setAuthModel({ data: null, error: true });
        });
    },
    async refreshTokenModel() {
      return await getCreds().then(async (response) => {
        return await authTransport.refreshToken(response.refresh.token).then((res) => {
          if (res && res.access_token) {
            const newCreds = {
              access: {
                ...response.access,
                token: res.access_token,
              },
              refresh: {
                ...response.refresh,
                token: res.refresh_token,
              },
            };
            saveCreds(newCreds);
            return newCreds;
          } else {
            clearCreds();
            dispatch.authModel.clearAuthModel();
            SocketIOService.disconnect();
            return undefined;
          }
        });
      });
    },
    deleteAuthModel() {
      dispatch.authModel.setAuthModelLoading(true);
      authTransport
        .deleteAuthModel()
        .then(() => {
          clearCreds();
          dispatch.authModel.clearAuthModel();
          SocketIOService.disconnect();
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
    async getAuthModelCode() {
      dispatch.authModel.setAuthModelLoading(true);
      return await authTransport
        .getAuthModelCode()
        .then((response) => {
          localStorage.setItem('codeVerifier', response.codeVerifier);
          return response;
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
    async getAuthModelCodeVerifier() {
      dispatch.authModel.setAuthModelLoading(true);
      return new Promise((resolve, reject) => {
        const codeVerifier = localStorage.getItem('codeVerifier');

        if (codeVerifier) {
          resolve(codeVerifier);
        } else {
          reject();
        }
      })
        .then((response) => {
          localStorage.removeItem('codeVerifier');
          return response;
        })
        .catch((error) => {
          console.error(error);
          message.error('Something went wrong. Please contact the dev team if the issue remains.');
          dispatch.authModel.setAuthModel({ data: null, error: true });
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
  }),
});
