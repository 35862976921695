import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useStopwatch } from 'react-timer-hook';
import { convertToTimerFormat, getCurrentTimerValue, padTo2Digits } from 'common/helpers/time.helper';
import { getStopwatchOffset } from 'entities/Timer/Timer.helper';
import { IEventTimerModel } from 'entities/Timer/Timer.models';

interface IComponentProps {
  autoStartTimer: boolean;
  canStart: boolean;
  timerModel: IEventTimerModel;
  editDisabled?: boolean;
  getTimerModel: () => void;
  setTimerModelUntrackedTime: (value: number) => void;
  onStartClick: () => void;
  onStopClick: () => void;
  onEditClick: (
    hours: number,
    minutes: number,
    seconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void
  ) => void;
}

export const Timer: React.FC<IComponentProps> = (props) => {
  const {
    autoStartTimer,
    canStart,
    timerModel,
    editDisabled,
    getTimerModel,
    setTimerModelUntrackedTime,
    onStartClick,
    onStopClick,
    onEditClick,
  } = props;
  const { data: timerModelData, isRunning, loading } = timerModel;
  const [startTimeValue, setStartTimeValue] = useState<number>(0);
  const { days, hours, minutes, seconds, start, pause, reset } = useStopwatch({ autoStart: autoStartTimer });
  const currentHours = hours + days * 24;

  useEffect(() => {
    getTimerModel();
  }, []);

  useEffect(() => {
    reset(getStopwatchOffset(getCurrentTimerValue(timerModelData?.time)), autoStartTimer && canStart);
  }, [timerModelData, autoStartTimer, canStart]);

  useEffect(() => {
    autoStartTimer && canStart && onStartClick();
  }, [autoStartTimer, canStart]);

  useEffect(() => {
    if (isRunning) {
      setStartTimeValue((currentHours * 3600 + minutes * 60 + seconds) * 1000);
      start();
    } else {
      pause();
    }
  }, [isRunning]);

  useEffect(() => {
    setTimerModelUntrackedTime((currentHours * 3600 + minutes * 60 + seconds) * 1000 - startTimeValue);
  }, [seconds]);

  if (!timerModelData) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <div className="timer">
        <p className="title timer__title">
          <span>Timer:</span>
          <span className="timer__count">
            {`${padTo2Digits(currentHours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`}
          </span>
        </p>

        <div className="timer__btns-wrapper">
          {canStart ? (
            isRunning ? (
              <Button className="btn-orange" onClick={onStopClick} icon={<PauseCircleOutlined />}>
                Stop
              </Button>
            ) : (
              <Button className="btn-primary" onClick={onStartClick} icon={<PlayCircleOutlined />}>
                Start
              </Button>
            )
          ) : null}
          <Button
            onClick={() => onEditClick(currentHours, minutes, seconds, reset)}
            icon={<EditOutlined />}
            disabled={isRunning || editDisabled}
          >
            Edit
          </Button>
        </div>

        <p className="timer__spent-time">{`Overall spent time for the event: ${convertToTimerFormat(
          timerModelData.totalTime
        )}`}</p>
      </div>
    </Spin>
  );
};
