import { DEFAULT_PAGE_SIZE } from 'common/config';

export const toDataSourseMapper = (data?: any[]) => data?.map((item, index) => ({ key: item.id || index, ...item }));

export const queryParamsToPayloadMapper = (params: any, pageId: number) => {
  return {
    ...params,
    offset: DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: DEFAULT_PAGE_SIZE,
  };
};
