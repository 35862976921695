import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IUsersCollection, IUsersCollectionDto } from 'entities/Users/Users.models';
import { usersTransport } from 'entities/Users/Users.transport';

export const usersCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IUsersCollection,
  reducers: {
    setUsersCollection: (state, payload: IUsersCollectionDto) => ({ ...state, data: payload }),
    setUsersCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getUsersCollection() {
      dispatch.usersCollection.setUsersCollectionLoading(true);
      return usersTransport
        .getUsersCollection()
        .then((response) => {
          dispatch.usersCollection.setUsersCollection({ ...response, data: [{ id: -1, name: 'All' }, ...response.data] });
        })
        .finally(() => {
          dispatch.usersCollection.setUsersCollectionLoading(false);
        });
    },
  }),
});
