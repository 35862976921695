import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EEventStatuses, EEventTypesShort, ESearchParams } from 'common/const/enums.const';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { useSocket } from 'common/hooks/useSocket';
import { SearchInput } from 'common/components/SearchInput';
import { DatePicker } from 'common/components/DatePicker';
import { Menu } from 'common/components/Menu';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { sort } from 'common/helpers/sorter.helper';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState, history } from 'app/store';
import { List } from 'entities/Users/components/List';
import { getEventsSearchParams, renderEventsRecords } from 'entities/Dashboard/Dashboard.helper';
import { eventsMenuItems, eventTypesList } from 'entities/Dashboard/Dashboard.const';
import { eventsQueryParamsToPayloadMapper } from 'entities/Dashboard/Dashboard.mapper';
import { getUsersOptions } from 'entities/Users/Users.helper';
import { IEventsItemDto } from 'entities/Dashboard/Dashboard.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const DashboardBodyComponent: React.FC<AllType> = (props) => {
  const { authModel, eventsCollection, usersCollection, eventModel, getEventsCollection, changeEventStatus } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const navigate = useNavigate();
  const { data: authModelData } = authModel;
  const { data: eventsCollectionData, loading: eventsCollectionLoading } = eventsCollection;
  const { data: usersCollectionData, loading: usersCollectionLoading } = usersCollection;
  const { loading: eventModelLoading } = eventModel;
  const currentUid = authModelData?.access?.uid;
  const currentUserRole = authModelData?.access?.role;

  const params = getEventsSearchParams(searchParams);
  const dataSource = toDataSourseMapper(eventsCollectionData?.data);
  const columns = renderEventsRecords(params.status);
  const listOptions = getUsersOptions(usersCollectionData?.data);
  const paramsRef = React.useRef(eventsQueryParamsToPayloadMapper(params, pageId, currentUid, currentUserRole));

  const { status, search, dateFrom, dateTo, orderField, orderDirection, assigneeId, type } = params;

  useSocket('events', () => {
    getEventsCollection(paramsRef.current);
  });

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const listValueChange = (value: string | number) => {
    setSearchParam(ESearchParams.AssigneeId, value.toString());
  };

  const getListValue = () => {
    if (assigneeId) {
      return assigneeId;
    } else {
      // -1 - id for the All PCC Queue option
      return currentUserRole === 'pcc' ? currentUid : -1;
    }
  };

  const onEventTypeChange = (value: string | number) => {
    if (value === EEventTypesShort.All) {
      removeSearchParam(ESearchParams.EventType);
    } else {
      setSearchParam(ESearchParams.EventType, value.toString());
    }
  };

  const getEventTypeValue = () => {
    return type || EEventTypesShort.All;
  };

  const menuTabChange = (value: string) => {
    setSearchParam(ESearchParams.Status, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  const onReviewClick = (eventId: number) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    changeEventStatus({ eventId, status: EEventStatuses.UnderReview }).then(() => {
      navigate(`/event/${eventId}?autoStartTimer=true`, { replace: true });
    });
  };

  const onViewClick = (eventId: number) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    navigate(`/event/${eventId}`, { replace: true });
  };

  const onCompleteClick = (eventId: number) => {
    changeEventStatus({ eventId, status: EEventStatuses.Completed });
  };

  const getDashboardItemActionContent = (record: IEventsItemDto) => {
    if (status === EEventStatuses.Inbox) {
      return (
        <>
          <Button className="btn-link" onClick={() => onReviewClick(record.id)}>
            Review
          </Button>
          {!record.hasUncompletedTasks && (
            <Button className="btn-link" onClick={() => onCompleteClick(record.id)}>
              Complete
            </Button>
          )}
        </>
      );
    } else {
      return (
        <Button className="btn-link" onClick={() => onViewClick(record.id)}>
          View
        </Button>
      );
    }
  };

  columns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => <div>{getDashboardItemActionContent(record)}</div>,
  });

  useEffect(() => {
    const payload = eventsQueryParamsToPayloadMapper(params, pageId, currentUid, currentUserRole);

    getEventsCollection(payload);
  }, [status, search, dateFrom, dateTo, orderField, orderDirection, assigneeId, pageId, type]);

  useEffect(() => {
    paramsRef.current = eventsQueryParamsToPayloadMapper(params, pageId, currentUid, currentUserRole);
  });

  return (
    <div className="dashboard-body">
      <div className="filter">
        <div className="filter__row">
          <SearchInput
            value={search}
            onChange={searchValueChange}
            placeholder="Search by patient / assignee / description / RBI number / patient phone number"
          />

          <DatePicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            className="dashboard-body__date-picker"
            onChange={dateRangeValueChange}
          />

          <List title="PCC Queue" options={listOptions} value={getListValue()} onChange={listValueChange} />

          <List
            title="Event Type"
            options={eventTypesList}
            value={getEventTypeValue()}
            className="dashboard-body__event-type-filter"
            onChange={onEventTypeChange}
          />
        </div>

        <div className="filter__row">
          <Menu items={eventsMenuItems} selectedKeys={[status]} onChange={menuTabChange} />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        loading={eventsCollectionLoading || usersCollectionLoading || eventModelLoading}
        onChange={handleSort}
      />

      <Pagination
        current={Number(pageId)}
        total={eventsCollectionData?.meta.count}
        showSizeChanger={false}
        onChange={(pageId: number) => setPageId(pageId)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventsCollection: state.eventsCollection,
  usersCollection: state.usersCollection,
  eventModel: state.eventModel,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getEventsCollection: dispatch.eventsCollection.getEventsCollection,
  changeEventStatus: dispatch.eventModel.changeEventStatus,
});

export const DashboardBody = connect(mapState, mapDispatch)(DashboardBodyComponent);
