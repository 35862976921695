export const envConfig = (window as any).env as {
  EVENT_IDLE_LIMIT_MS: string;
  EVENT_MOVE_TO_QUEUE_LIMIT_MS: string;
};
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_ID = 1;
export const DEFAULT_MODAL_WIDTH = 600;
export const EVENT_IDLE_LIMIT_MS = envConfig.EVENT_IDLE_LIMIT_MS ? Number(envConfig.EVENT_IDLE_LIMIT_MS) : 120000;
export const EVENT_MOVE_TO_QUEUE_LIMIT_MS = envConfig.EVENT_MOVE_TO_QUEUE_LIMIT_MS
  ? Number(envConfig.EVENT_MOVE_TO_QUEUE_LIMIT_MS)
  : 60000;
