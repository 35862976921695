import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

type AllType = ReturnType<typeof mapState>;

const OperationsSubheaderComponent: React.FC<AllType> = ({ statisticsModel }) => {
  const { operationsStatisticsData, loading } = statisticsModel;
  return (
    <Spin spinning={loading}>
      <div className="statistics__container">
        <StatisticsItem title="Total number" subtitle="of patients" count={operationsStatisticsData?.patientsTotal} />
        <StatisticsItem
          title="Total billable events"
          subtitle="Last 30 days"
          count={operationsStatisticsData?.eventsTotal}
          highlighter="gray"
        />
        <StatisticsItem
          title="Total billable hours"
          subtitle="Last 30 days"
          count={operationsStatisticsData?.hoursTotal && Math.round(operationsStatisticsData?.hoursTotal)}
          highlighter="gray"
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statisticsModel: state.statisticsModel,
});

export const OperationsSubheader = connect(mapState)(OperationsSubheaderComponent);
