import React from 'react';
import { ReactComponent as OpenIcon } from 'app/assets/images/svg/open-icon.svg';
import { IRelatedCarePlan } from 'entities/Event/Event.models';

interface IComponentProps {
  relatedCarePlans: IRelatedCarePlan[];
  contraindicationsLink: string;
}

export const EventDetails: React.FC<IComponentProps> = ({ relatedCarePlans, contraindicationsLink }) => {
  return (
    <div className="event-details">
      <div className="subtitle">Event Details</div>
      <div className="event-details__care-plan">
        <span>Related Care Plan(s):</span>
        {relatedCarePlans.map((item) => {
          return (
            <a key={item.id} className="event-details__link" onClick={() => window.open(item.link, '_blank')}>
              <span>{item.id}</span>
              <OpenIcon />
            </a>
          );
        })}
      </div>
      <a className="event-details__link" onClick={() => window.open(contraindicationsLink, '_blank')}>
        <span>Contraindications</span>
        <OpenIcon />
      </a>
    </div>
  );
};
