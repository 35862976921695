import { EEventTaskStatuses, EEventTaskStatusTitles } from 'common/const/enums.const';

export const eventTasksOptions = [
  {
    value: EEventTaskStatuses.Completed,
    label: EEventTaskStatusTitles.Completed,
  },
  {
    value: EEventTaskStatuses.NotCompleted,
    label: EEventTaskStatusTitles.NotCompleted,
  },
];
