import React from 'react';
import { clearCreds, configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { message } from 'antd';
import { IAsyncStorage, WebStorageDecorator } from 'universal-storage';
import ReactDOM from 'react-dom/client';
import 'app/assets/sass/index.scss';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { SocketIOService } from 'common/SocketIoService';
import { App } from 'app/pages/App';
import store, { history } from 'app/store';

// Axios initialization
const storage: IAsyncStorage = new WebStorageDecorator(localStorage);
configureAxiosJWTInterseptors({
  storage,
  // @ts-ignore
  axios,
  refreshTokenEndpoint: '/auth',
});

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use((config) => config);
axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const { config, response } = error;

    if (error.status === 500) {
      message.error('Something went wrong');
      return Promise.reject(error);
    }

    if (response?.status === 403) {
      clearCreds();
      store.dispatch.authModel.clearAuthModel();
      SocketIOService.disconnect();
      return Promise.reject(error);
    }

    if (config.url !== '/auth' && response?.status === 401) {
      try {
        const res = await store.dispatch.authModel.refreshTokenModel();
        if (res && res.access.token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${res.access.token}`;
          return axios(config);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }

    if (response) {
      const { status, statusText, data } = response;
      return Promise.reject({ data, status, statusText });
    } else {
      return Promise.reject(error);
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
