import { createModel } from '@rematch/core';
import { exportCSVFile } from 'common/helpers/csv.helper';
import { IRootModel } from 'app/store';
import { IOperationsCollection, IOperationCollectionDto, IOperationsPayload } from 'entities/Operations/Operations.models';
import { operationsTransport } from 'entities/Operations/Operations.transport';

export const operationsCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IOperationsCollection,
  reducers: {
    setOperationsCollection: (state, payload: IOperationCollectionDto) => ({ ...state, data: payload }),
    setOperationsCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getOperationsCollection(params: IOperationsPayload) {
      dispatch.operationsCollection.setOperationsCollectionLoading(true);
      operationsTransport
        .getOperationsCollection(params)
        .then((response) => {
          dispatch.operationsCollection.setOperationsCollection(response);
          dispatch.statisticsModel.getOperationsStatistics();
        })
        .finally(() => {
          dispatch.operationsCollection.setOperationsCollectionLoading(false);
        });
    },
    async getOperationsReport(params: IOperationsPayload) {
      dispatch.operationsCollection.setOperationsCollectionLoading(true);
      operationsTransport
        .getOperationsReport(params)
        .then((response) => exportCSVFile(response, 'operations'))
        .finally(() => {
          dispatch.operationsCollection.setOperationsCollectionLoading(false);
        });
    },
  }),
});
