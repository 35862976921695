import React from 'react';
import { connect } from 'react-redux';
import { EEventStatuses, EEventTaskStatuses } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { Tasks } from 'entities/Tasks/components/Tasks';

interface IComponentProps {
  eventStatus: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventTasksComponent: React.FC<AllType> = (props) => {
  const { eventStatus, tasksCollection, changeEventTaskStatus } = props;
  const { data: tasksCollectionData, loading } = tasksCollection;

  const onChange = (taskId: string, status: EEventTaskStatuses) => {
    changeEventTaskStatus({ taskId, status });
  };

  if (!tasksCollectionData || !tasksCollectionData.length) {
    return null;
  }

  return (
    <Tasks
      tasks={tasksCollectionData}
      loading={loading}
      onChange={onChange}
      disabled={eventStatus === EEventStatuses.Completed}
    />
  );
};

const mapState = (state: RootState) => ({
  tasksCollection: state.tasksCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeEventTaskStatus: dispatch.tasksCollection.changeEventTaskStatus,
});

export const EventTasks = connect(mapState, mapDispatch)(EventTasksComponent);
