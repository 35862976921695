import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { EActionTypes, EEventTypes } from 'common/const/enums.const';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { NavigationSubheader } from 'common/components/NavigationSubheader';
import { RootDispatch, RootState, history } from 'app/store';
import { OutdatedModal } from 'entities/Modals/components/OutdatedModal';
import { EventBody } from 'entities/Event/components/EventBody';
import { EventPanel } from 'entities/Event/components/EventPanel';
import CommentModal from 'entities/Modals/components/CommentModal';
import { getEventAvailableOperations } from 'entities/Event/Event.helper';
import TimeEditorModal from 'entities/Modals/components/TimeEditorModal';
import { InactiveModal } from 'entities/Modals/components/InactiveModal';
import { EventRouterHandler } from 'entities/Event/components/EventRouterHandler';
import { HoldModal } from 'entities/Modals/components/HoldModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EventComponent: React.FC<AllType> = (props) => {
  const { authModel, eventModel, patientModel, phoneModel, getEventById, clearModels, setActionParams } = props;
  const { data: authModelData } = authModel;
  const { data: eventModelData, loading } = eventModel;
  const { data: patientModelData } = patientModel;
  const { blockRouter } = phoneModel;

  const currentUserId = authModelData?.access?.userId;
  const assigneeId = eventModelData?.assigneeId;
  const eventStatus = eventModelData?.status;

  const { id } = useParams();
  const eventId = Number(id);
  const autoStartTimer = new URLSearchParams(window.location.search).get('autoStartTimer') === 'true';
  const { canStart } = getEventAvailableOperations(eventStatus, currentUserId, assigneeId);

  const modals = [
    { key: uuid(), modal: <CommentModal /> },
    { key: uuid(), modal: <TimeEditorModal withComment /> },
    { key: uuid(), modal: <InactiveModal /> },
    { key: uuid(), modal: <HoldModal /> },
    { key: uuid(), modal: <OutdatedModal /> },
  ];

  const onBackClick = () => {
    setActionParams({ type: EActionTypes.Back });
    history.back();
  };

  useEffect(() => {
    getEventById(eventId);
  }, []);

  useEffect(() => {
    return () => {
      setActionParams({ type: '', text: undefined, holdUntil: undefined, holdReason: undefined, comment: undefined });
      clearModels();
    };
  }, []);

  if (!eventModelData || !patientModelData) {
    return (
      <Row justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Spin spinning={loading}>
      <CommonContentLayout
        subheader={
          <NavigationSubheader
            link="Dashboard"
            description={EEventTypes[eventModelData.type as keyof typeof EEventTypes]}
            onClick={onBackClick}
          />
        }
        body={<EventBody eventModelData={eventModelData} patientModelData={patientModelData} />}
        panel={<EventPanel eventId={eventId} autoStartTimer={autoStartTimer} />}
        modals={modals}
      />

      {blockRouter && <EventRouterHandler eventId={eventId} canStart={canStart} />}
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventModel: state.eventModel,
  patientModel: state.patientModel,
  phoneModel: state.phoneModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEventById: dispatch.eventModel.getEventById,
  clearModels: dispatch.eventModel.clearModels,
  setActionParams: dispatch.actionModel.setActionParams,
});

export const Event = connect(mapState, mapDispatch)(EventComponent);
