import React, { useState } from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { IOption } from 'common/models';
import { RootState } from 'app/store';
import { getOutdatedModalReasonOptions } from 'entities/Modals/Modals.helper';

type AllType = ReturnType<typeof mapState>;

const OutdatedModalComponent: React.FC<AllType> = ({ outdatedModalParams, loading }) => {
  const { open, onCancelClick, onOutdatedClick } = outdatedModalParams;
  const [outdatedReason, setOutdatedReason] = useState<string>();

  const options = getOutdatedModalReasonOptions();

  const handleCancelClick = () => {
    setOutdatedReason(undefined);
    onCancelClick?.();
  };

  const handleOutdatedClick = () => {
    setOutdatedReason(undefined);
    onOutdatedClick?.(outdatedReason);
  };

  const onCommentChange = (value: IOption) => {
    setOutdatedReason(value.value as string);
  };

  return (
    <Modal
      className="hold-modal"
      title="Outdated"
      open={open}
      footer={false}
      onCancel={handleCancelClick}
      width={DEFAULT_MODAL_WIDTH}
    >
      <Spin spinning={loading}>
        <div className="modal__body">
          <Select
            popupClassName="hold-modal__select-dropdown"
            labelInValue
            placeholder="Reason"
            onChange={onCommentChange}
            options={options}
          />
        </div>

        <div className="hold-modal__footer">
          <div className="hold-modal__footer-container">
            <Button className="btn-red" onClick={handleOutdatedClick} disabled={!outdatedReason?.length}>
              Mark Outdated
            </Button>

            <Button onClick={handleCancelClick}>Cancel</Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  outdatedModalParams: state.modals?.outdatedModalParams,
  loading: state.eventModel.loading,
});

export const OutdatedModal = connect(mapState)(OutdatedModalComponent);
